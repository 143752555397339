import React from "react";
import { UserProfileDropdown } from "../extras/dropdowns/UserProfileDropdown";
import { SERVER_URL } from "../../../../utils/index"

export function Topbar() {

  return (
    <div className="topbar">
      <button
        type="button"
        className="btn btn-primary"
        style={{ margin: 'auto', marginRight: '30px' }}
        onClick={() =>
          window.open(SERVER_URL + '/uploads/user_manual.pdf', '_blank')
        }
      >
        User manual
      </button>
      <UserProfileDropdown/>
    </div>
  );
}
