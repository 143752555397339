import React, { useEffect } from "react";
// import {Redirect} from "react-router-dom";
// import {LayoutSplashScreen} from "../../../../_metronic/layout";
// import * as auth from "../_redux/authRedux";

// class Logout extends Component {
//   componentDidMount() {
//     this.props.logout();
//   }

//   render() {
//     const { hasAuthToken } = this.props;
//     return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
//     // console.log('Needs to clean redux')
//     // console.log('Needs to clean cookies')
//     // console.log('Redirect to login page')
//     // return <Redirect to="/auth/login" />
//   }
// }

// export default connect(
//   ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
//   auth.actions
// )(Logout);

import { logout } from "../../../../api/user/index"

import { authActions } from '../_redux/authActions'
import { useDispatch } from 'react-redux'
import { SplashScreen } from "../../../../_metronic/_partials/controls";

function Logout() {
  const dispatch = useDispatch()

  useEffect(() => {
    async function clearCookies() {
      await logout()
    }
    clearCookies().then()
    dispatch(authActions.logoutAction())
  }, [dispatch])
  
  return (
    <SplashScreen/>
  )
}

export default Logout
