import { API, authClient } from '../../utils/index'

// Get all markets
export const getMarkets = () => {
    return authClient().get(`${API}/markets`)
}

// Get market by id
export const getMarketById = (id) => {
    return authClient().get(`${API}/markets/${id}`)
}

// Update market
export const updateMarket = async (id, market) => {
    return authClient().put(`${API}/markets/${id}`, market)
}

// Save market
export const postMarket = async (market) => {
    return authClient().post(`${API}/markets`, market)
}

// Delete market
export const deleteMarket = (id) => {
    return authClient().delete(`${API}/markets/${id}`)
}
