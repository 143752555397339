import { API, authClient } from '../../utils/index'

// Get all reviews
export const getReviews = () => {
    return authClient().get(`${API}/reviews`)
}

// Get review by id
export const getReviewById = (id) => {
    return authClient().get(`${API}/reviews/${id}`)
}

// Update review
export const updateReview = async (id, review) => {
    return authClient().put(`${API}/reviews/${id}`, review)
}

// Save review
export const postReview = async (review) => {
    return authClient().post(`${API}/reviews`, review)
}

// Delete review
export const deleteReview = (id) => {
    return authClient().delete(`${API}/reviews/${id}`)
}

