import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Checkbox,
  FormControlLabel,
  Tooltip
} from "@material-ui/core"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { getProductById, postProduct, updateProduct, deleteProduct } from "../../../../api/products"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { getCategories } from "../../../../api/categories"
import { getSpecifications } from "../../../../api/specifications"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { SERVER_URL } from "../../../../utils"
import { alertError, alertSuccess } from "../../../../utils/logger"
import CreatableSelect from 'react-select/creatable'
import DeleteIcon from '@material-ui/icons/Delete'
import { buttonsStyle } from '../../../components/tables/table'
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyProduct() {
  return {
    name: '',
    description: '',
    fullDescription: '',
    additionalInfoURL: '',
    additionalInfo: '',
    imagesURLs: [],
    price: { eur: '0.0' },
    originalPrice: { eur: '0.0' },
    code: '',
    measures: '',
    weight: '',
    material: '',
    origin: '',
    taric: '',
    unitsPerCase: 1,
    stock: 0,
    categories: [],
    specifications: [],
    WIP: false,
    discount: false,
    relatedProducts: [],
    seasonal: false,
    active: true,
    availabilityDate: null,
    featured: false,
    unlimitedStock: false
  }
}

const getDataSelect = (specifications) => {
  const data = []
  for (const specification of specifications) {
    let elem = {}
    elem.label = specification.name
    elem.value = specification._id
    data.push(elem)
  }
  return data
}

export default function EditProductPage() {
  const [product, setProduct] = useState(getEmptyProduct())
  const [selectedImages, setSelectedImages] = useState(null)
  const [deletedImages, setDeletedImages] = useState(null)
  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const [categories, setCategories] = useState(null)
  const [specifications, setSpecifications] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
  const [openPreviewCertificate, setOpenPreviewCertificate] = useState(false)
  const productId = useParams().id
  const history = useHistory()
  let products = useLocation().state
  if (productId) {
    const index = products.findIndex(item => item.id === productId)
    if (index > -1) products = products.splice(index, 1)
  }

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getCategories().then(res => {
      if (res.status === 200) {
        setCategories(res.data)
        getSpecifications().then(resp => {
          if (resp.status === 200) {
            setSpecifications(getDataSelect(resp.data))
            if (!productId) {
              disableLoadingData()
            }
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get specifications.' })
          history.push('/products')
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get categories.' })
      history.push('/products')
    })
    if (productId) {
      getProductById(productId).then(res => {
        disableLoadingData()
        if (res.status === 200) {
          setProduct(res.data)
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not get product.' })
        history.push('/products')
      })
    }
  }, [productId, disableLoadingData, history])

  function saveProduct() {
    if (typeof product.price.eur === 'string') {
      product.price.eur = product.price.eur.includes(',') ? product.price.eur.replace(',', '.') : product.price.eur
      if (isNaN(product.price.eur)) {
        alertError({ error: null, customMessage: 'Price should be a number.' })
        return
      }
      product.price.eur = parseFloat(product.price.eur)
    }
    if (typeof product.originalPrice?.eur === 'string') {
      product.originalPrice.eur = product.originalPrice.eur.includes(',') ? product.originalPrice.eur.replace(',', '.') : product.originalPrice.eur
      if (isNaN(product.originalPrice.eur)) {
        alertError({ error: null, customMessage: 'Original price should be a number.' })
        return
      }
      product.originalPrice.eur = parseFloat(product.originalPrice.eur)
    }
    if (!productId) {
      if (!selectedImages) {
        alertError({ error: null, customMessage: 'Please upload at least one image.' })
        return
      }
      postProduct(product, selectedImages, selectedCertificate).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Product saved successfully.' })
          history.push('/products')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save product.' })
      })
    } else {
      updateProduct(productId, product, selectedImages, selectedCertificate).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes made have been saved successfully.' })
          history.push('/products')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Changes could not be saved.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    if (element === 'eur') {
      const newPrice = product.price
      newPrice[element] = event.target.value
      setProduct({ ...product, price: newPrice })
    } else if (element === 'discount-eur') {
      const newPrice = product.originalPrice
      newPrice['eur'] = event.target.value
      setProduct({ ...product, originalPrice: newPrice })
    } else setProduct({ ...product, [element]: event.target.value })
  }

  const handleChangeSpecifications = (event) => {
    setProduct({ ...product, specifications: event })
  }

  const handleCreate = (event) => {
    const newSpecifications = specifications
    const newTag = { value: 'new-' + event, label: event }
    newSpecifications.push(newTag)
    setSpecifications(newSpecifications)
    setProduct({ ...product, specifications: product.specifications.concat(newTag) })
  }

  const handleDeleteImage = (index) => {
    // TODO: DELETE IMAGES FROM SERVER
    let newImages = [...product.imagesURLs]
    const deletedImage = newImages.splice(index, 1)
    setProduct({ ...product, imagesURLs: newImages })
    if (selectedImages && selectedImages[index]) {
      let _selectedImages = {}
      for (const [key, value] of Object.entries(selectedImages)) {
        if (key !== index) {
          if (key > index) {
            _selectedImages[key - 1] = value
          } else _selectedImages[key] = value
        } 
      }
      if (!Object.keys(_selectedImages).length) _selectedImages = null
      setSelectedImages(_selectedImages)
    } else setDeletedImages(deletedImages ? deletedImages.concat(deletedImage) : [deletedImage])
  }

  const handleMoveImage = (index, newIndex) => {
    const aux = product.imagesURLs[index]
    let images = [...product.imagesURLs]
    images.splice(index, 1, images[newIndex])
    images.splice(newIndex, 1, aux)
    setProduct({ ...product, imagesURLs: images })
    if (selectedImages && (selectedImages[index] || selectedImages[newIndex])) {
      let _selectedImages = {}
      for (const [key, value] of Object.entries(selectedImages)) {
        if (key === index.toString()) _selectedImages[newIndex] = value
        else if (key === newIndex.toString()) _selectedImages[index] = value
        else _selectedImages[key] = value
      }
      setSelectedImages(_selectedImages)
    }
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit product'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={product.name}
            onChange={handleChange('name')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`description`}
            label="Short description"
            value={product.description}
            onChange={handleChange('description')}
            multiline={true}
            required
            rows={4}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id={`fullDescription`}
            label="Description"
            value={product.fullDescription}
            onChange={handleChange('fullDescription')}
            multiline={true}
            rows={10}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id={`code`}
            label="Code"
            value={product.code}
            onChange={handleChange('code')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id={`measures`}
            label="Measures (cm)"
            value={product.measures}
            onChange={handleChange('measures')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`weight`}
            label="Weight (gr)"
            value={product.weight}
            onChange={handleChange('weight')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`material`}
            label="Material"
            value={product.material}
            onChange={handleChange('material')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`origin`}
            label="Origin"
            value={product.origin}
            onChange={handleChange('origin')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id={`taric`}
            label="Taric code"
            value={product.taric}
            onChange={handleChange('taric')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id={`unitsPerCase`}
            label="Units per case"
            value={product.unitsPerCase}
            onChange={handleChange('unitsPerCase')}
            InputLabelProps={{
              shrink: true
            }}
            type="number"
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`stock`}
            label="Quantity available (stock)"
            value={product.stock}
            onChange={handleChange('stock')}
            InputLabelProps={{
              shrink: true
            }}
            disabled={product.unlimitedStock}
            type="number"
            margin="normal"
            variant="outlined"
            required
          />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={product.unlimitedStock} onChange={() => setProduct({ ...product, unlimitedStock: !product.unlimitedStock })} name="checkUnlimitedStock" />}
            label="Unlimited stock"
          />
          <br />
          <br />
          <TextField
            id={`price-eur`}
            label="Price (€)"
            value={product.price.eur && !isNaN(product.price.eur) ? Number(product.price.eur).toLocaleString("es-ES") : product.price.eur}
            onChange={handleChange('eur')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={product.featured} onChange={() => setProduct({ ...product, featured: !product.featured })} name="checkFeatured" />}
            label="Featured"
          />
          {/*<FormControlLabel
            control={<Checkbox checked={product.seasonal} onChange={() => setProduct({ ...product, seasonal: !product.seasonal })} name="checkSeasonal" />}
            label="Seasonal"
          />*/}
          <FormControlLabel
            control={<Checkbox checked={product.WIP} onChange={() => setProduct({ ...product, WIP: !product.WIP })} name="checkWIP" />}
            label="Work in progress"
          />
          {product.WIP && <TextField
              id="date"
              label="Availability date"
              type="date"
              value={product.availabilityDate && product.availabilityDate.includes('T') ? product.availabilityDate.split('T')[0] : product.availabilityDate}
              onChange={handleChange('availabilityDate')}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
            />
          }
          <FormControlLabel
            control={<Checkbox checked={product.discount} onChange={() => setProduct({ ...product, discount: !product.discount })} name="checkDiscount" />}
            label="Discounted product"
          />
          {product.discount && <TextField
            id={`discount-price-eur`}
            label="Original price (€)"
            value={product.originalPrice?.eur && !isNaN(product.originalPrice?.eur) ? Number(product.originalPrice?.eur).toLocaleString("es-ES") : product.originalPrice?.eur}
            onChange={handleChange('discount-eur')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required={product.discount}
          />}
          <TextField
            id={`additionalInfoURL`}
            label="Certificate URL"
            value={product.additionalInfoURL}
            onChange={handleChange('additionalInfoURL')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <br />
          <br />
          <label htmlFor={"upload-certificate"}>
            <input
              style={{ display: 'none' }}
              id={"upload-certificate"}
              name={"upload-certificate"}
              type="file"
              onChange={(e) => {
                setSelectedCertificate(e.target.files[0])
              }}
            />
            <Button
              style={{ marginRight: '15px' }}
              color="secondary"
              component="span"
              variant="outlined">
              {((selectedCertificate || product.additionalInfo !== '') ? 'Change certificate' : 'Upload certificate')}
            </Button>
          </label>
          {(selectedCertificate || product.additionalInfo !== '') &&
            <>
              <Button
                onClick={() => setOpenPreviewCertificate(true)}
                style={{ marginRight: '15px' }}
                variant="outlined">
                {'Preview certificate'}
              </Button>
              <PreviewDialog
                title={'Preview certificate'}
                open={openPreviewCertificate}
                setOpen={setOpenPreviewCertificate}
                src={(selectedCertificate) ? URL.createObjectURL(selectedCertificate) : `${SERVER_URL}/${product.additionalInfo}`}
              />
              <Tooltip title="Delete">
                <Button
                  size="small"
                  style={buttonsStyle}
                  onClick={() => {
                    setSelectedCertificate(null)
                    setProduct({ ...product, additionalInfo: '' })
                  }}>
                  <DeleteIcon/>
                </Button>
              </Tooltip>
              <br />
            </>
          }
          <br />
          <br />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label">Categories</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={product.categories}
              multiple
              onChange={handleChange('categories')}
              MenuProps={MenuProps}
            >
              {categories?.map((option) => (
                <MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Select at least one category</FormHelperText>
          </FormControl>
          <br />
          <br />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Related products</InputLabel>
            <Select
              labelId="demo-multiple-select"
              id="demo-multiple-select"
              multiple
              value={product.relatedProducts}
              onChange={handleChange('relatedProducts')}
              MenuProps={MenuProps}
            >
              {products?.map((item) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Select all related products</FormHelperText>
          </FormControl>
          <br />
          <br />
          <br />
          <FormControl style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Specifications</InputLabel>
            <CreatableSelect
              isClearable
              isMulti
              value={product.specifications}
              onChange={handleChangeSpecifications}
              onCreateOption={handleCreate}
              options={specifications}
            />
            <FormHelperText>Select all specifications</FormHelperText>
          </FormControl>
          <br />
          <br />
          <br />
          {product.imagesURLs?.map((_, index) => {
            return (
              <>
                <label htmlFor={"upload-image" + index}>
                  <input
                    style={{ display: 'none' }}
                    id={"upload-image" + index}
                    name={"upload-image" + index}
                    type="file"
                    accept={'image/*, video/*'}
                    onChange={(e) => {
                      setSelectedImages({ ...selectedImages, [index]: e.target.files[0] })
                    }}
                  />
                  <Button
                    style={{ marginRight: '15px' }}
                    color="secondary"
                    component="span"
                    variant="outlined">
                    {((selectedImages && selectedImages[index]) || product.imagesURLs[index] !== '') ? 'Change file ' + (index + 1) : 'Upload file ' + (index + 1)}
                  </Button>
                </label>
                {((selectedImages && selectedImages[index]) || product.imagesURLs[index] !== '') &&
                  <>
                    <Tooltip title={'Preview file ' + (index + 1)}>
                      <Button
                        size="small"
                        onClick={() => setOpenPreviewDialog(index)}
                        style={buttonsStyle}>
                        <Visibility/>
                      </Button>
                    </Tooltip>
                    <PreviewDialog
                      title={'Preview file ' + (index + 1)}
                      open={openPreviewDialog === index}
                      setOpen={setOpenPreviewDialog}
                      src={(selectedImages && selectedImages[index]) ? URL.createObjectURL(selectedImages[index]) : `${SERVER_URL}/${product.imagesURLs[index]}`}
                    />
                    <Tooltip title="Delete">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => handleDeleteImage(index)}>
                        <DeleteIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move up">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={index === 0}
                        onClick={() => handleMoveImage(index, index - 1)}>
                        <ArrowUpward/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move down">
                      <Button
                        size="small"
                        disabled={!(index < product.imagesURLs.length - 1 && ((selectedImages && selectedImages[index + 1]) || product.imagesURLs[index + 1] !== ''))}
                        style={{...buttonsStyle, marginRight: '1em' }}
                        onClick={() => handleMoveImage(index, index + 1)}>
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                    <span>
                      {selectedImages && selectedImages[index] ? selectedImages[index]?.name : (product.imagesURLs[index] !== '' ? product.imagesURLs[index].split(/-(.*)/s)[1] : '')}
                    </span>
                    <br />
                  </>
                }
              </>
            )
          })}
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setProduct({ ...product, imagesURLs: product.imagesURLs.concat('') })}
          >
            Add file
          </Button>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={product.active} onChange={() => setProduct({ ...product, active: !product.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/products')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveProduct()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save product
      </Button>

      {productId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            style={{ marginRight: '20px' }}
            color="secondary">
            Delete product
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this product?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteProduct(productId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Product deleted succesfully.' })
                history.push('/products')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete product.' })
            })
          }}
        />
      </>}
    </>
  );
}


