import axios from 'axios'

export const baseClient = () => {
  return axios.create()
}

export const authClient = () => {
  return axios.create({
    withCredentials: true
  })
}

const PRO = (process.env.NODE_ENV ===  'production' || process.env.NODE_ENV === 'pro')

export const API = PRO
  ? 'https://api.zcbrandscatalogues.com:4002/api'
  : 'http://localhost:4003/api'

export const SERVER_URL = PRO
  ? 'https://api.zcbrandscatalogues.com:4002'
  : 'http://localhost:4003'