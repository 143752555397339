import { API, authClient } from '../../utils/index'
import { uploadSingleFile } from "../files"

// Get all categories
export const getCategories = () => {
    return authClient().get(`${API}/categories`)
}

// Get category by id
export const getCategoryById = (id) => {
    return authClient().get(`${API}/categories/${id}`)
}

// Update category
export const updateCategory = async (id, category, image) => {
    if (image) {
        const response = await uploadSingleFile(image, 'categories')
        category.imageURL = response.data.fileURL
    }
    return authClient().put(`${API}/categories/${id}`, category)
}

// Save category
export const postCategory = async (category, image) => {
    const response = await uploadSingleFile(image, 'categories')
    category.imageURL = response.data.fileURL
    return authClient().post(`${API}/categories`, category)
}

// Delete category
export const deleteCategory = (id) => {
    return authClient().delete(`${API}/categories/${id}`)
}

