import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import { Button, Tooltip } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { deleteCategory, getCategories } from "../../../../api/categories"
import { alertError, alertSuccess } from "../../../../utils/logger"

function getData(categories) {
  let data = []
  for (let i = 0; i < categories.length; ++i) {
    const elem = {}
    elem.name = categories[i].name
    elem.createdAt = categories[i].createdAt
    elem.id = categories[i]._id
    data = data.concat(elem)
  }
  return data
}

export default function CategoriesPage() {
  const [data, setData] = useState([])
  const [categoryId, setCategoryId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button style={buttonsStyle} size="small" onClick={() => history.push('/edit-category/' + cell)}><EditIcon/></Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setCategoryId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name' },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getCategories().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get categories.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Categories list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=> history.push('/edit-category')}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to delete this category?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCategory(categoryId).then(res => {
                if (res.status === 204) {
                  alertSuccess({  title: 'Deleted!', customMessage: 'Category successfully deleted.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not delete category.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

