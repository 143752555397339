import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle, booleanFormatter } from '../../../components/tables/table'
import { Button, Tooltip } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { deleteProduct, getProducts } from "../../../../api/products"
import { alertError, alertSuccess } from "../../../../utils/logger"
import { getCategories } from "../../../../api/categories"

function getData(products, categories) {
  let data = []
  for (let i = 0; i < products.length; ++i) {
    const elem = {}
    const productCategories = products[i].categories?.length ? 
      categories.filter(cat => products[i].categories?.includes(cat._id))?.map(cat => cat.name)?.join(', ') : '---'
    elem.name = products[i].name
    elem.description = products[i].description
    elem.categories = productCategories
    elem.createdAt = products[i].createdAt
    elem.active = products[i].active
    elem.id = products[i]._id
    data = data.concat(elem)
  }
  return data
}

export default function ProductsPage() {
  const [data, setData] = useState([])
  const [productId, setProductId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button style={buttonsStyle} size="small" onClick={() => history.push({ pathname: '/edit-product/' + cell, state: data })}><EditIcon/></Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setProductId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name' },
    { dataField: 'description', text: 'Description' },
    { dataField: 'categories', text: 'Categories' },
    { dataField: 'active', text: 'Active', formatter: booleanFormatter, align: 'center', headerAlign: 'center' },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getProducts().then((res) => {
      if (res.status === 200) {
        getCategories().then((resp) => {
          if (resp.status === 200) {
            setData(getData(res.data, resp.data))
            setRefresh(false)
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get categories.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get products.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Product list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push({ pathname: '/edit-product', state: data })}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to delete this product?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteProduct(productId).then(res => {
                if (res.status === 204) {
                  alertSuccess({ title: 'Deleted!', customMessage: 'Product successfully deleted.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not delete product.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

