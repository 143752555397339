import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { Button, TextField, MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { getCategoryById, postCategory, updateCategory, deleteCategory } from "../../../../api/categories"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { TextRow } from "react-placeholder/lib/placeholders"
import ReactPlaceholder from "react-placeholder"
import { alertError, alertSuccess } from "../../../../utils/logger"
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../utils";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyCategory() {
  return {
    name: '',
    imageURL: ''
  }
}

const placeholder = (
  <Card>
    <CardBody>
      <div id='tabs' className='m-7'>
        <TextRow showLoadingAnimation color='#CDCDCD' style={{ width: '100%', height: 50 }} />
      </div>
    </CardBody>
  </Card>
)

export default function EditCategoryPage() {
  const [category, setCategory] = useState(getEmptyCategory())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)
  const categoryId = useParams().id
  const history = useHistory()
  const { isLoading: isLoadingData, disableLoading: disableLoadingData } = useSkeleton()

  useEffect(() => {
    if (!categoryId) {
      disableLoadingData()
      return
    }
    getCategoryById(categoryId).then(res => {
      if (res.status === 200) {
        setCategory(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get category.' })
      history.push('/categories')
    })
  }, [categoryId, disableLoadingData, history])

  function saveCategory() {
    if (!categoryId) {
      if (!selectedImage) {
        alertError({ error: null, customMessage: 'Please upload an image.' })
        return
      }
      postCategory(category, selectedImage).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Category saved successfully.' })
          history.push('/categories')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save category.' })
      })
    } else {
      updateCategory(categoryId, category, selectedImage).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes made have been saved successfully.' })
          history.push('/categories')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Changes could not be saved.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setCategory({ ...category, [element]: event.target.value })
  }
  if (isLoadingData)
    return <ReactPlaceholder showLoadingAnimation customPlaceholder={placeholder} />
  return (
    <>
      <Card>
        <CardHeader title='Edit category'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={category.name}
            onChange={handleChange('name')}
            InputLabelProps={{
              shrink: true
            }}
            required
            margin="normal"
            variant="outlined"
          />
          <br />
          <br />
          <br />
          <label htmlFor="upload-image">
            <input
              style={{ display: 'none' }}
              id="upload-image"
              name="upload-image"
              type="file"
              accept={'image/*'}
              onChange={(e) => setSelectedImage(e.target.files[0])}
            />
            <Button
              style={{ marginRight: '15px' }}
              color="secondary"
              component="span"
              variant="outlined">
              {(selectedImage || category.imageURL !== '') ? 'Change image' : 'Upload image'}
            </Button>
          </label>
          {(selectedImage || category.imageURL !== '') &&
            <>
              <Button
                onClick={() => setOpenPreviewDialog(true)}
                variant="outlined">
                Preview image
              </Button>
              <PreviewDialog
                title={'Preview image'}
                open={openPreviewDialog}
                setOpen={setOpenPreviewDialog}
                src={selectedImage ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/${category.imageURL}`}
              />
            </>
          }
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/categories')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveCategory()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save category
      </Button>

      {categoryId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete category
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this category?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteCategory(categoryId).then(res => {
              if (res.status === 204) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Category deleted successfully' })
                history.push('/categories')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete category.' })
            })
          }}
        />
      </>}
    </>
  );
}

