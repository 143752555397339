import React from "react";
import {CircularProgress} from "@material-ui/core";
import {toAbsoluteUrl} from "../../_helpers";

export function SplashScreen() {
  return (
    <div id="splash-screen" className="kt-splash-screen">
      <div className="splash-screen">
        <img
          src={toAbsoluteUrl("/media/logos/logo.png")}
          alt="Lolea logo"
          style={{ maxWidth: '50px'}}
        />
        <CircularProgress className="splash-screen-spinner" />
      </div>
    </div>
  );
}