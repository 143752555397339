import { API, authClient } from '../../utils/index'

// Get all orders
export const getOrders = () => {
    return authClient().get(`${API}/orders`)
}

// Get order by id
export const getOrderById = (id) => {
    return authClient().get(`${API}/orders/${id}`)
}

// Update order
export const updateOrder = async (id, order) => {
    return authClient().put(`${API}/orders/${id}`, order)
}

// Save order
export const postOrder = async (order) => {
    return authClient().post(`${API}/orders`, order)
}

// Delete order
export const deleteOrder = (id) => {
    return authClient().delete(`${API}/orders/${id}`)
}

