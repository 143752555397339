import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import ProductsPage from "./modules/ContentManager/products/ProductsPage";
import EditProductPage from "./modules/ContentManager/products/EditProductPage";
import CategoriesPage from "./modules/ContentManager/categories/CategoriesPage";
import EditCategoryPage from "./modules/ContentManager/categories/EditCategoryPage";
import SpecificationsPage from "./modules/ContentManager/specifications/SpecificationsPage";
import EditSpecificationPage from "./modules/ContentManager/specifications/EditSpecificationPage";
import UsersPage from "./modules/ContentManager/users/UsersPage";
import EditUsersPage from "./modules/ContentManager/users/EditUsersPage";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
//import InformedsPage from "./modules/ContentManager/informeds/InformedsPage";
//import EditInformedsPage from "./modules/ContentManager/informeds/EditInformedsPage";
import OrdersPage from "./modules/ContentManager/orders/OrdersPage";
import ReviewsPage from "./modules/ContentManager/reviews/ReviewsPage";
//import KeyBrandFilesPage from "./modules/ContentManager/keyBrandFiles/KeyBrandFilesPage";
//import EditKeyBrandFilesPage from "./modules/ContentManager/keyBrandFiles/EditKeyBrandFilesPage";
import MarketsPage from "./modules/ContentManager/markets/MarketsPage";
import EditMarketPage from "./modules/ContentManager/markets/EditMarketPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          <Redirect exact from="/" to="/products" />
        }
        {/* Products */}
        <ContentRoute from="/products" component={ProductsPage} />
        <ContentRoute from="/edit-product/:id?" component={EditProductPage} />
        {/* Categories */}
        <ContentRoute from="/categories" component={CategoriesPage} />
        <ContentRoute from="/edit-category/:id?" component={EditCategoryPage} />
        {/* Specifications */}
        <ContentRoute from="/specifications" component={SpecificationsPage} />
        <ContentRoute from="/edit-specification/:id?" component={EditSpecificationPage} />
        {/* Administrators */}
        <ContentRoute from="/admins" component={AdminsPage} />
        <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
        {/* Informeds */}
        {/*<ContentRoute from="/informeds" component={InformedsPage} />
        <ContentRoute from="/edit-informed/:id?" component={EditInformedsPage} />*/}
        {/* Users */}
        <ContentRoute from="/users" component={UsersPage} />
        <ContentRoute from="/edit-user/:id?" component={EditUsersPage} />
        {/* Orders */}
        <ContentRoute from="/orders" component={OrdersPage} />
        {/* Reviews */}
        <ContentRoute from="/reviews" component={ReviewsPage} />
        {/* Key brand files */}
        {/*<ContentRoute from="/key-brand-files" component={KeyBrandFilesPage} />
        <ContentRoute from="/edit-key-brand-file/:id?" component={EditKeyBrandFilesPage} />*/}
        {/* Markets */}
        <ContentRoute from="/markets" component={MarketsPage} />
        <ContentRoute from="/edit-market/:id?" component={EditMarketPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
