import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { Button, TextField, MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { getSpecificationById, postSpecification, updateSpecification, deleteSpecification } from "../../../../api/specifications"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { useSkeleton } from "../../../hooks/useSkeleton"
import { TextRow } from "react-placeholder/lib/placeholders"
import ReactPlaceholder from "react-placeholder"
import { alertError, alertSuccess } from "../../../../utils/logger"

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptySpecification() {
  return {
    name: ''
  }
}

const placeholder = (
  <Card>
    <CardBody>
      <div id='tabs' className='m-7'>
        <TextRow showLoadingAnimation color='#CDCDCD' style={{ width: '100%', height: 50 }}/>
      </div>
    </CardBody>
  </Card>
)

export default function EditSpecificationPage() {
  const [specification, setSpecification] = useState(getEmptySpecification())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const specificationId = useParams().id
  const history = useHistory()
  const { isLoading: isLoadingData, disableLoading: disableLoadingData } = useSkeleton()

  useEffect(() => {
    if (!specificationId) {
      disableLoadingData()
      return
    }
    getSpecificationById(specificationId).then(res => {
      if (res.status === 200) {
        setSpecification(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get specification.' })
      history.push('/specifications')
    })
  }, [specificationId, disableLoadingData, history])

  function saveSpecification() {
    if (!specificationId) {
      postSpecification(specification).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Specification saved successfully.' })
          history.push('/specifications')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save specification.' })
      })
    } else {
      updateSpecification(specificationId, specification).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes made have been saved successfully.' })
          history.push('/specifications')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Changes could not be saved.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setSpecification({ ...specification, [element]: event.target.value })
  }
  if (isLoadingData)
    return <ReactPlaceholder showLoadingAnimation customPlaceholder={placeholder} />
  return (
    <>
      <Card>
        <CardHeader title='Edit specification'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`name`}
            label="Name"
            value={specification.name}
            onChange={handleChange('name')}
            InputLabelProps={{
                shrink: true
            }}
            required
            margin="normal"
            variant="outlined"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/specifications')}
        variant="outlined"
        style={{marginRight: '20px'}}>
        Back
      </Button>
      <Button
        onClick={() => saveSpecification()}
        variant="outlined"
        color="primary"
        style={{marginRight: '20px'}}>
        Save specification
      </Button>

      {specificationId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete specification
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this specification?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteSpecification(specificationId).then(res => {
              if (res.status === 204) {
                alertSuccess({  title: 'Deleted!', customMessage: 'Specification deleted successfully' })
                history.push('/specifications')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete specification.' })
            })
          }}
        />
      </>}
    </>
  );
}

