import Swal from 'sweetalert2'

// Error messages from api returned as {message: 'error message text'}
export const logError = ({ error, customMessage = 'No custom message from backoffice' }) => {
  error?.response?.status
  	? console.log (
  		'ERROR\n' +
        `Status: ${error.response.status}.\n` +
        `Status error: ${error.response.statusText}.\n` +
        `API Message: ${error.response.data?.message}\n` +
        `Backoffice Message: ${customMessage}`
  	)
  	: console.log (
  		'ERROR\n' +
        `Backoffice message: ${customMessage}\n` +
        `${error}`
  	)
}

export const alertError = ({ error, customMessage }) => {
	let message =
		error?.response?.data?.message ||
		error?.response?.statusText ||
		error ||
		customMessage ||
		'An unexpected error occurred while performing the operation.'

	if (JSON.stringify(message).includes('Network Error')) message = "Please check that you have an Internet connection. Check the firewall settings or if there are any blockers activated on your computer. If the problem persists, contact your company's IT department."

	const params = {
		icon: 'error',
		title: 'Ooops...',
		text: message,
		confirmButtonText: 'Ok'
	}

	Swal.fire(params).then()
}

export const alertSuccess = ({ title, customMessage } = {}) => {
	const message =
		customMessage ||
		'Operation has been completed successfully!'

	const params = {
		icon: 'success',
		title,
		text: message,
		confirmButtonText: 'Ok'
	}

	Swal.fire(params).then()
}