import { API, authClient } from '../../utils/index'

// Get all specifications
export const getSpecifications = () => {
    return authClient().get(`${API}/specifications`)
}

// Get specification by id
export const getSpecificationById = (id) => {
    return authClient().get(`${API}/specifications/${id}`)
}

// Update specification
export const updateSpecification = async (id, specification) => {
    return authClient().put(`${API}/specifications/${id}`, specification)
}

// Save specification
export const postSpecification = async (specification) => {
    return authClient().post(`${API}/specifications`, specification)
}

// Delete specification
export const deleteSpecification = (id) => {
    return authClient().delete(`${API}/specifications/${id}`)
}

