import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { getReviews, deleteReview } from "../../../../api/reviews"
import { getProducts } from "../../../../api/products"
import { Button, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { alertError, alertSuccess } from "../../../../utils/logger"

function getData(reviews, products) {
  let data = []
  for (let i = 0; i < reviews.length; ++i) {
    const elem = {}
    elem.name = reviews[i].name
    elem.email = reviews[i].email
    elem.product = products.find(item => item._id === reviews[i].product)?.name
    elem.rating = reviews[i].rating
    elem.comment = reviews[i].comment
    elem.createdAt = reviews[i].createdAt
    elem.id = reviews[i]._id
    data = data.concat(elem)
  }
  return data
}

export default function ReviewsPage() {
  const [data, setData] = useState([])
  const [reviewId, setReviewId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setReviewId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon />
        </Button>
      </Tooltip>
    </>)
  }


  const columns = [
    { dataField: 'name', text: 'Name' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'product', text: 'Product' },
    { dataField: 'rating', text: 'Rating' },
    { dataField: 'comment', text: 'comment' },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getProducts().then((resp) => {
      if (resp.status === 200) {
        getReviews().then((res) => {
          if (res.status === 200) {
            setData(getData(res.data, resp.data))
            setRefresh(false)
          }
        }).catch(error => {
          alertError({ error: error, customMessage: 'Could not get reviews.' })
        })
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get products.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Reviews list' />
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to delete this review?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteReview(reviewId).then(res => {
                if (res.status === 204) {
                  alertSuccess({ title: 'Deleted!', customMessage: 'Review removed successfully.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not remove review.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}

