import { /* baseClient, */ authClient, API } from '../../utils/index'

export const login = (credentials) => {
  return authClient().post(`${API}/user/signinAdmin`, credentials)
}

export const register = (body) => {
  return authClient().post(`${API}/user/signup`, body);
}

export const logout = () => {
  return authClient().get(`${API}/user/logout`)
}

export const forgotPassword = (credentials) => {
  return authClient().post(`${API}/user/forgot-password-admin`, credentials)
}

export const resetPassword = ({ password, id }) => {
	const body = { password }
  return authClient().put(`${API}/user/forgot-password/${id}`, body)
}

export const getCredentials = () => {
  return authClient().get(`${API}/user/credentials`)
}

export const refreshTokens = () => {
  return authClient().get(`${API}/user/refresh`)
}

// Get all users
export const getUsers = () => {
  return authClient().get(`${API}/user`)
}

// Get user by id
export const getUserById = (id) => {
  return authClient().get(`${API}/user/${id}`)
}

// Delete user
export const deleteUser = (id) => {
  return authClient().delete(`${API}/user/${id}`)
}

// Validate user
export const validateUser = (id) => {
  return authClient().put(`${API}/user/validate/${id}`)
}

// Create user
export const postUser = (user) => {
  return authClient().post(`${API}/user`, user)
}

// Update user
export const updateUser = (id, user) => {
  return authClient().put(`${API}/user/${id}`, user)
}