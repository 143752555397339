import { API, authClient } from '../../utils/index'
import { uploadFilesGetLinks, uploadSingleFile } from "../files"

// Get all products
export const getProducts = () => {
    return authClient().get(`${API}/products`)
}

// Get product by id
export const getProductById = (id) => {
    return authClient().get(`${API}/products/${id}`)
}

// Update product
export const updateProduct = async (id, product, images, certificate) => {
    if (images) {
        for (const index in images) {
            const response = await uploadSingleFile(images[index], 'products')
            product.imagesURLs[index] = response.data.fileURL
        }
    }
    if (certificate) {
        const response = await uploadSingleFile(certificate, 'products')
        product.additionalInfo = response.data.fileURL
    }
    return authClient().put(`${API}/products/${id}`, product)
}

// Save product
export const postProduct = async (product, images, certificate) => {
    const imagesURLs = await uploadFilesGetLinks(images, 'products')
    product.imagesURLs = imagesURLs
    if (certificate) {
        const response = await uploadSingleFile(certificate, 'products')
        product.additionalInfo = response.data.fileURL
    }
    return authClient().post(`${API}/products`, product)
}

// Delete product
export const deleteProduct = (id) => {
    return authClient().delete(`${API}/products/${id}`)
}

