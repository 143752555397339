/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import CategoryIcon from '@material-ui/icons/Category'
import GroupIcon from '@material-ui/icons/Group'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import RateReviewIcon from '@material-ui/icons/RateReview'
//import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import SettingsIcon from '@material-ui/icons/Settings'
//import ContactMailIcon from '@material-ui/icons/ContactMail';
import PublicIcon from '@material-ui/icons/Public';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Contenido */}
        {/* begin::section */}
        <li className="menu-section "><h4 className="menu-text text-white">Products</h4><i className="menu-icon flaticon-more-v2"></i></li>
        <li
          className={`menu-item ${getMenuItemActive("/products", false)} ${getMenuItemActive("/edit-product", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/products">
            <span className="menu-icon" style={{ color: 'white'}}>
              <ShoppingCart/>
            </span>
            <span className="menu-text">Products</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/categories", false)} ${getMenuItemActive("/edit-category", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/categories">
            <span className="menu-icon" style={{ color: 'white'}}>
              <CategoryIcon/>
            </span>
            <span className="menu-text">Categories</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/specifications", false)} ${getMenuItemActive("/edit-specification", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/specifications">
            <span className="menu-icon" style={{ color: 'white'}}>
              <LocalOfferIcon/>
            </span>
            <span className="menu-text">Specifications</span>
          </NavLink>
        </li>
        <li className="menu-section "><h4 className="menu-text text-white">Orders</h4><i className="menu-icon flaticon-more-v2"></i></li>
        <li
          className={`menu-item ${getMenuItemActive("/orders", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/orders">
            <span className="menu-icon" style={{ color: 'white'}}>
              <ReceiptIcon/>
            </span>
            <span className="menu-text">Orders</span>
          </NavLink>
        </li>
        <li className="menu-section "><h4 className="menu-text text-white">Profiles</h4><i className="menu-icon flaticon-more-v2"></i></li>
        <li
          className={`menu-item ${getMenuItemActive("/users", false)} ${getMenuItemActive("/edit-user", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="menu-icon" style={{ color: 'white'}}>
              <GroupIcon/>
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        {/*<li
          className={`menu-item ${getMenuItemActive("/informeds", false)} ${getMenuItemActive("/edit-informed", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/informeds">
            <span className="menu-icon" style={{ color: 'white'}}>
              <ContactMailIcon/>
            </span>
            <span className="menu-text">Area Managers</span>
          </NavLink>
        </li>*/}
        <li
          className={`menu-item ${getMenuItemActive("/admins", false)} ${getMenuItemActive("/edit-admin", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admins">
            <span className="menu-icon" style={{ color: 'white'}}>
              <SettingsIcon/>
            </span>
            <span className="menu-text">Administrators</span>
          </NavLink>
        </li>
        <li className="menu-section "><h4 className="menu-text text-white">Miscellany</h4><i className="menu-icon flaticon-more-v2"></i></li>
        <li
          className={`menu-item ${getMenuItemActive("/reviews", false)} ${getMenuItemActive("/view-review", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reviews">
            <span className="menu-icon" style={{ color: 'white'}}>
              <RateReviewIcon/>
            </span>
            <span className="menu-text">Reviews</span>
          </NavLink>
        </li>
        {/*<li
          className={`menu-item ${getMenuItemActive("/key-brand-files", false)} ${getMenuItemActive("/edit-key-brand-file", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/key-brand-files">
            <span className="menu-icon" style={{ color: 'white'}}>
              <InsertDriveFile/>
            </span>
            <span className="menu-text">Key Brand Files</span>
          </NavLink>
        </li>*/}
        <li
          className={`menu-item ${getMenuItemActive("/markets", false)} ${getMenuItemActive("/view-market", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/markets">
            <span className="menu-icon" style={{ color: 'white'}}>
              <PublicIcon/>
            </span>
            <span className="menu-text">Markets</span>
          </NavLink>
        </li>
        {/* end:: section */}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
