import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle, booleanFormatter } from '../../../components/tables/table'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { getUsers, deleteUser, validateUser } from "../../../../api/user"
import { Button, Tooltip } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import EditIcon from "@material-ui/icons/Edit"
import { alertError, alertSuccess } from "../../../../utils/logger"
import { useHistory } from 'react-router-dom'

function getData(users) {
  let data = []
  for (let i = 0; i < users.length; ++i) {
    if (users[i].role === 'client') {
      const elem = {}
      elem.name = users[i].fullName
      elem.email = users[i].email
      elem.verified = users[i].verified
      elem.validated = users[i].validated
      elem.areaManager = users[i].areaManager
      elem.createdAt = users[i].createdAt
      elem.id = users[i]._id
      data = data.concat(elem)
    }
  }
  return data
}

export default function UsersPage() {
  const [data, setData] = useState([])
  const [userId, setUserId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openConfirmValidation, setOpenConfirmValidation] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title='Validate'>
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setUserId(cell)
            setOpenConfirmValidation(true)
          }}>
          <CheckCircleIcon/>
        </Button>
      </Tooltip>
      <Tooltip title="Edit">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-user/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setUserId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }


  const columns = [
    { dataField: 'name', text: 'Full name' },
    { dataField: 'email', text: 'Email' },
    { dataField: 'verified', text: 'Email verified', align: 'center', headerAlign: 'center', formatter: booleanFormatter },
    { dataField: 'validated', text: 'User validated', align: 'center', headerAlign: 'center', formatter: booleanFormatter },
    { dataField: 'areaManager', text: 'Area Manager', align: 'center', headerAlign: 'center', formatter: booleanFormatter },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getUsers().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get users.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
      <CardHeader title='Users list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-user')}
              >
                Add new
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to unsubscribe this user?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteUser(userId).then(res => {
                if (res.status === 204) {
                  alertSuccess({  title: 'Deleted!', customMessage: 'User removed successfully.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not remove user.' })
              })
            }}
          />
          <ConfirmDialog
            title={'Are you sure you want to validate this user?'}
            open={openConfirmValidation}
            setOpen={setOpenConfirmValidation}
            onConfirm={() => {
              validateUser(userId).then(res => {
                if (res.status === 200) {
                  alertSuccess({  title: 'Validated!', customMessage: 'User validated successfully. An email has been sent to this user to notify him.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not validate user.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
