import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls"
import Table, { dateFormatter, buttonsStyle } from '../../../components/tables/table'
import { Button, Tooltip } from "@material-ui/core"
import { useHistory } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { deleteMarket, getMarkets } from "../../../../api/markets"
import { alertError, alertSuccess } from "../../../../utils/logger"

function getData(markets) {
  let data = []
  for (let i = 0; i < markets.length; ++i) {
    const elem = {}
    elem.name = markets[i].name
    elem.createdAt = markets[i].createdAt
    elem.id = markets[i]._id
    data = data.concat(elem)
  }
  return data
}

export default function MarketsPage() {
  const [data, setData] = useState([])
  const [marketId, setMarketId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button style={buttonsStyle} size="small" onClick={() => history.push('/edit-market/' + cell)}><EditIcon/></Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setMarketId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'name', text: 'Name' },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getMarkets().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get markets.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Markets list'>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={()=> history.push('/edit-market')}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to delete this market?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteMarket(marketId).then(res => {
                if (res.status === 204) {
                  alertSuccess({  title: 'Deleted!', customMessage: 'Market successfully deleted.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not delete market.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
